@import "src/assets/styles/custom-style.scss";
@import "src/assets/styles/variables";
@import "src/assets/styles/font-style.scss";
@import 'src/assets/styles/bootstrap.scss';
@import "src/assets/styles/popover.scss";
@import 'node_modules/swiper/dist/css/swiper.min.css';
@import 'src/assets/styles/glide';
@import 'src/assets/styles/clevertap';

body {
  font-family: var(--primary-font-family);
}
/*iOs issue for popup*/
*{-webkit-overflow-scrolling:initial !important;}
.bg-img {
  margin: 8px;
  background-image: url('/assets/images/Bitmap.jpg');
}

.bg-gray {
  margin: 8px;
  background-color: #3d3d3d;
}
.bg-grey {
  background-color: #3d3d3d;
}
textarea{
  resize: none !important;
}
.bg-light-gray {
  background-color: #f7f7f7;
}
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media (min-width: 150px){
  .app-container {
    max-width: 500px;
  }
}

.container-body {
  margin: 0px 10px;
}

.request-sent {
  transition: height 0.3s ease-out;
  .mat-dialog-container{
    border-radius: 10px;
  }
}


.app-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 10px;
}

.error-message {
  color: #f44336;
}

.captcha-revalidate-btn {
  margin-top: 5px;
  background-color: #f44336 !important;
  color: #fff !important;
}

.text-center {
  text-align: center;
}
@media screen and (min-width:768px) {
  .sipPopContainer{
    width: 600px;
    height: 472px;
    border-radius: 16px !important;
  }
}
@media screen and (max-width:767px) {
  .sipPopContainer{
    width: 100%;
    max-width: 320px !important;
    border-radius: 16px !important;
  }
}
@media screen and (max-width:330px) {
  .sipPopContainer{
    width: 96%;
    // max-width: 320px !important;
    // height: 472px;
    // border-radius: 16px !important;
  }
}


.mt-30 {
  margin-top: 30px !important;
}

.mt-15 {
  margin-top: 15px
}

.m-10 {
  margin: 10px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.form-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  font-size: 17px;
}

.input-container {
  width: 100%;
  // margin-top: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.fund-button {
  color: #fff !important;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 16px;
  background-color: var(--primary-color) !important;
}

.fund-button-container {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

mat-card-footer {
  height: 50px;
}

.card-title {
  font-size: 18px !important;
  font-weight: bold;
}

.text-primary {
  color: var(--primary-color);
}

.icon-primary {
  -webkit-text-fill-color: var(--primary-color);
}

.line {
  margin: 0px -16px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  padding: 0;
}

.line-vertical {
  border: 1px solid #d8d8d8;
  padding: 0;
  width: 0px;
  height: 35px;
  margin: inherit;
}

.line-vertical:hover {
  border: 1px solid var(--primary-color);
}

.flex-container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 99;
  margin: auto 8px;
}

.full-screen-dialog {
  max-width: 100vw;
  max-height: 100vw;
  width: 100vw;
  height: 100vh;
}

.fb-btn {
  background-color: #4267B2;
}

.primary-btn {
  background-color: var(--primary-color);
  color: #fff;
}

.full-card {
  min-height: 80vh;
  @media(min-width: 992px){
    min-height: 600px;
  }
}
.saf-container{
  @media(min-width: 992px){
    .full-card{
      min-height: 550px;
    }
    .mat-card-content{
      margin-bottom: 30px;
    }
  }
}

.snackbar-success {
  background-color: #252525;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  color: #fff;

  button {
    color: #01bfbd;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
  }
}

.snackbar-error {
  background: #252525;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  color: #fff;

  button {
    color: #E82943;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
  }
}

.mat-form-field {
  line-height: 1;
}

.mat-icon {
  overflow: unset !important;
  // margin: auto;
}

.mat-button-wrapper {
  .mat-icon {
    margin: auto;
  }
}

.mat-form-field .mat-icon{
  height: auto;
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  align-items: flex-start !important;
}

.mat-button.mat-primary .mat-button-focus-overlay {
  background-color: transparent;
}

.sticky-header {
  position: fixed !important;
  top: 44px !important;
  z-index: 5 !important;
  width: 100%;

}

@media (max-width: 768px) {
  .round-dialog {
    width: 100%;
    margin: auto;
    // max-width: 90vw !important;
  }
  .mat-drawer-container{
    position: unset !important;
  }
}

.app-button {
  -webkit-appearance: none !important;
}

.app-input {
  margin: 5px;
}

.first-occur {
  font-size: 14px;
  text-align: center;

  app-donate-now ::ng-deep .donation-button {
    font-size: 14px !important;
  }
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

button,
button:hover,
button:focus {
  text-decoration: none;
  outline: none;
}

/*Moved from homepage*/
@media (min-width: 992px) {
  .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='50' height='50' viewBox='0 0 24 35'%3e%3cdefs%3e%3cpath id='b' d='M3.467 378.966l13.066-11.487-13.066 11.487zm0 .513l13.066 11.487-13.066-11.487z'/%3e%3cfilter id='a' width='215.1%25' height='165.9%25' x='-57.6%25' y='-24.7%25' filterUnits='objectBoundingBox'%3e%3cfeMorphology in='SourceAlpha' operator='dilate' radius='1.5' result='shadowSpreadOuter1'/%3e%3cfeOffset dy='2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'/%3e%3cfeMorphology in='SourceAlpha' radius='1.5' result='shadowInner'/%3e%3cfeOffset dy='2' in='shadowInner' result='shadowInner'/%3e%3cfeComposite in='shadowOffsetOuter1' in2='shadowInner' operator='out' result='shadowOffsetOuter1'/%3e%3cfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='2'/%3e%3cfeColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3e%3c/filter%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round' transform='translate(2 -364)'%3e%3cuse fill='%23000' filter='url(%23a)' xlink:href='%23b'/%3e%3cuse stroke='%2301BFBD' stroke-width='3' xlink:href='%23b'/%3e%3c/g%3e%3c/svg%3e ");
    background-size: 50px 50px;
    &:focus  {
      outline: none;
    }
  }

  .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='50' height='50' viewBox='0 0 24 35'%3e%3cdefs%3e%3cpath id='b' d='M328.467 378.966l13.066-11.487-13.066 11.487zm0 .513l13.066 11.487-13.066-11.487z'/%3e%3cfilter id='a' width='215.1%25' height='165.9%25' x='-57.6%25' y='-24.7%25' filterUnits='objectBoundingBox'%3e%3cfeMorphology in='SourceAlpha' operator='dilate' radius='1.5' result='shadowSpreadOuter1'/%3e%3cfeOffset dy='2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'/%3e%3cfeMorphology in='SourceAlpha' radius='1.5' result='shadowInner'/%3e%3cfeOffset dy='2' in='shadowInner' result='shadowInner'/%3e%3cfeComposite in='shadowOffsetOuter1' in2='shadowInner' operator='out' result='shadowOffsetOuter1'/%3e%3cfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='2'/%3e%3cfeColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3e%3c/filter%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round' transform='matrix(-1 0 0 1 347 -364)'%3e%3cuse fill='%23000' filter='url(%23a)' xlink:href='%23b'/%3e%3cuse stroke='%2301BFBD' stroke-width='3' xlink:href='%23b'/%3e%3c/g%3e%3c/svg%3e ");
    background-size: 50px 50px;
    &:focus  {
      outline: none;
    }
  }
  .round-dialog {
    width: 30%;
    margin: auto;
    &.contact-dialog {
      .mat-dialog-container {
        padding: 0px;
      }
    }
    .mat-dialog-container {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
      border-radius: 10px !important;
    }
  }
}

@media (max-width: 991px) {
  .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='35' viewBox='0 0 24 35'%3e%3cdefs%3e%3cpath id='b' d='M3.467 378.966l13.066-11.487-13.066 11.487zm0 .513l13.066 11.487-13.066-11.487z'/%3e%3cfilter id='a' width='215.1%25' height='165.9%25' x='-57.6%25' y='-24.7%25' filterUnits='objectBoundingBox'%3e%3cfeMorphology in='SourceAlpha' operator='dilate' radius='1.5' result='shadowSpreadOuter1'/%3e%3cfeOffset dy='2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'/%3e%3cfeMorphology in='SourceAlpha' radius='1.5' result='shadowInner'/%3e%3cfeOffset dy='2' in='shadowInner' result='shadowInner'/%3e%3cfeComposite in='shadowOffsetOuter1' in2='shadowInner' operator='out' result='shadowOffsetOuter1'/%3e%3cfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='2'/%3e%3cfeColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3e%3c/filter%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round' transform='translate(2 -364)'%3e%3cuse fill='%23000' filter='url(%23a)' xlink:href='%23b'/%3e%3cuse stroke='%2301BFBD' stroke-width='3' xlink:href='%23b'/%3e%3c/g%3e%3c/svg%3e ");
    &:focus  {
      outline: none;
    }
  }

  .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='35' viewBox='0 0 24 35'%3e%3cdefs%3e%3cpath id='b' d='M328.467 378.966l13.066-11.487-13.066 11.487zm0 .513l13.066 11.487-13.066-11.487z'/%3e%3cfilter id='a' width='215.1%25' height='165.9%25' x='-57.6%25' y='-24.7%25' filterUnits='objectBoundingBox'%3e%3cfeMorphology in='SourceAlpha' operator='dilate' radius='1.5' result='shadowSpreadOuter1'/%3e%3cfeOffset dy='2' in='shadowSpreadOuter1' result='shadowOffsetOuter1'/%3e%3cfeMorphology in='SourceAlpha' radius='1.5' result='shadowInner'/%3e%3cfeOffset dy='2' in='shadowInner' result='shadowInner'/%3e%3cfeComposite in='shadowOffsetOuter1' in2='shadowInner' operator='out' result='shadowOffsetOuter1'/%3e%3cfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='2'/%3e%3cfeColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3e%3c/filter%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round' transform='matrix(-1 0 0 1 347 -364)'%3e%3cuse fill='%23000' filter='url(%23a)' xlink:href='%23b'/%3e%3cuse stroke='%2301BFBD' stroke-width='3' xlink:href='%23b'/%3e%3c/g%3e%3c/svg%3e ");
    &:focus  {
      outline: none;
    }
  }
  .cdk-overlay-dark-backdrop{
    background: rgba(0,0,0,.70) !important;
  }
}

a:hover,
a:focus {
  text-decoration: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(50, 50, 50, .25);
  border: 2px solid transparent;
  border-radius: 10px;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  background-color: rgba(50, 50, 50, .05);
}
.payment-dialog .mat-dialog-container {
  padding: 0px;
  overflow-x: hidden;
}

.hubble-onboard-dialog .mat-dialog-container {
  margin: 0 !important;
  padding: 0px;
  overflow-x: hidden;
  border-radius: 0 !important;
  height: 100vh;
}

.view-suppoters-dialog .mat-dialog-container {
  overflow: hidden;
}

.mat-button,
.mat-chip.mat-standard-chip,
.mat-icon-button,
.mat-raised-button,
.mat-stroked-button {

  &.cdk-focused,
  &.cdk-program-focused {
    background-color: none !important;

    .mat-button-focus-overlay {
      display: none !important;
    }
  }
}

// #launcher {
//   bottom: 45px !important;
// }
@media(max-width: 768px){
  #launcher{
    bottom: 45px!important;
    z-index: 9 !important;
  }
}

// .g-image-item {
//   background-size: contain !important;
// }

* {
  -webkit-overflow-scrolling: touch;
}
button, [type="button"], [type="reset"], [type="submit"]{
  -webkit-appearance: none !important;
}

/*Mat dropdown collapses when screen gets scrolled on top*/
.cdk-overlay-connected-position-bounding-box, .mat-menu-panel.desktopMenu{
  height: auto !important;
  min-height: 100% !important;
}
/*Global change for placeholder of the material*/
.mat-form-field-appearance-legacy .mat-form-field-label{
  color: #999999 !important;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label{
  color: #f44336 !important;
}


input::placeholder {
  color: #999999 !important;
}

@media(min-width: 992px){
  .login-popup {
    mat-dialog-container{
      background: transparent;
      box-shadow: none
    }
  }
}
.homePage .zEWidget-launcher,
.how-it-works .zEWidget-launcher{
  bottom: 140px !important;
}

/*Material dialog Modal removed html scrolling when it gets open Globally, Specically for ios mobile device lagging issues*/
html.cdk-global-scrollblock {
  overflow: initial !important;
  header{
    top: initial;
  }
}

/*Close button on the popup across the application*/
.mat-dialog-container .close-btn{
  position: absolute;
  border: 0;
  z-index: 9;
  width: 30px;
  height: 30px;
  background: #eeeeee;;
  box-shadow: none;
  .mat-icon{
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  }
}
.bottom-sheet-container {
  border-radius: 8px 8px 0px 0px;
  .close-icon .close{
    top: 10px !important;
    right: 10px !important;
  }
}
.bottom-sheet-container-extented {
  position: relative;
  border-radius: 8px 8px 0px 0px !important;
}
.sac-purpose-changer{
  max-width: 100vw !important;
  h2{
    font-size: 17px;
    font-weight: 600;
  }
}
.insurance-member-selector{
  .ok-btn{
    color: white;
  }
  .close i{
    font-size: 14px !important;
    padding: 3px !important;
  }
}
.custom-insurance-tnc{
  .ok-btn{
    color: white;
  }
}

.input-popover{
  @media(min-width: 992px){
    .popover-container{
      background: #039695;
      &.medical-popup {
        padding: 20px;
        border-radius: 10px;
        .close { text-align: center; }
        .p-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 90%;
          font-weight: bold;
          font-size: 17px;
        }
        .p-desc { font-size: 14px; margin-top: 10px; }
        ol { padding-left: 1em; }
      }
    }
  }
  @media (max-width: 768px) {
    .popover-container{
      background: #039695;
      &.medical-popup {
        padding: 20px;
        border-radius: 10px;
        .close { text-align: center; }
        .p-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 90%;
          font-weight: bold;
          font-size: 17px;
        }
        .p-desc { font-size: 14px; margin-top: 10px; }
        ol { padding-left: 1em; }
      }
    }
  }
}

.style-as-link{
  color: var(--primary-color);
  cursor: pointer;
}
.sample-image-popover{
  .popover-container{
    width: 300px;
  }
  .p-eg{
    cursor: pointer;
  }
}
.how-it-works-ip {
  border-radius: 8px 8px 0px 0px !important;
  max-height: 92vh !important;
}
.algolia-sac-static-options{
  .mat-option-text{
    color: var(--primary-color);
  }
}
/*mat autocomplete issue for ios replaced fixed position to absolute*/
// .hospital-saf{
//   .cdk-overlay-container{
//     position: absolute;
//   }
// }

// .mat-form-field.mat-focused .mat-form-field-ripple {
//   background-color: var(--primary-color) !important;
// }

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color:var(--primary-color);
}

.private-fund-bg{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(40, 40, 40, 0.5);
  backdrop-filter: blur(200px);
}

.pin-modal {
  border: 1px solid #F5F5F5;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.mat-select-panel-wrap .mat-select-panel.mat-primary{
  max-height: 350px;
}

.zsiq_custombtn {
  position: fixed !important;
  right: 6px;
  z-index: 15 !important;
}
.zls-sptwndw {
  right: -6px !important;
}
@media (min-width: 767px){
  .zsiq_custombtn {
    bottom: 150px;
  }
  .zls-sptwndw {
    bottom: 136px !important;
    right: -6px !important;
  }
}
@media (max-width: 768px){
  .zsiq_custombtn {
    bottom: 75px !important;
  }
  .zls-sptwndw {
    // bottom: 61px !important;
  }
}
.f-italic {
  font-style: italic !important;
}
.noscroll {
  overflow: hidden !important;
}

.gradient-border-animate {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    background: linear-gradient(120deg, #00F260, #0575E6, #00F260);
    background-size: 300% 300%;
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
  }

  &.in::after {
    animation: frame-enter 1s forwards ease-in-out reverse, gradient-animation 4s ease-in-out infinite;
  }
}


/* motion */
@keyframes gradient-animation {
  0% {
    background-position: 15% 0%;
  }
  50% {
    background-position: 85% 100%;
  }
  100% {
    background-position: 15% 0%;
  }
}

@keyframes frame-enter {
  0% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  25% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  50% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, 100% 0%, 0% 0%);
  }
  75% {
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 0%, 0% 0%);
  }
  100% {
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 0% 100%);
  }
}
.dock-visible-mb-50 {
  margin-bottom: 50px;
}
.dock-visible-mb-60 {
  margin-bottom: 60px;
}
.dock-visible-mb-70 {
  margin-bottom: 70px;
}
.dock-visible-mb-80 {
  margin-bottom: 80px;
}
.onboarding-wrapper .mat-dialog-container {
  padding: 0 !important;
  border-radius: 24px;
}

.onboarding-backdrop-img {
  background-image: url('/assets/images/sip/background/background-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 768px) {
  .onboarding-wrapper .mat-dialog-container {
    border-radius: initial;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.mat-bottom-sheet-container {
  &.contact-dialog {
    padding: 0px;
    border-radius: 24px 24px 0px 0px;
    max-height: 684px;
  }
}
.confirmation-modal-sheet {
  border-radius: 24px 24px 0 0 !important;
}
.utm-popup {
  .mat-dialog-container{
    padding: 0px !important;
    border-radius: 10px !important;
    clip-path: polygon(100% 0%, 100% 74%, 98% 79%, 100% 83%, 100% 100%, 0 100%, 0 83%, 2% 79%, 0 75%, 0 0);
  }
}

.finance-sheet .mat-bottom-sheet-container,
.location-sheet .mat-bottom-sheet-container,
.finance-success-sheet .mat-bottom-sheet-container {
  overflow: hidden !important;
  border-radius: 24px 24px 0px 0px;
  background: #FCFCFC;
  box-shadow: 0px -4px 20px 2px rgba(0, 0, 0, 0.10);
  padding: initial;
}

.hf-critical-illness-info {
  .mat-dialog-container {
    border-radius: 16px;
    padding: unset;
  }
  .mat-bottom-sheet-container {
    border-radius: 16px;
    padding: unset;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  } 
}
@media (max-width: 768px) {
  .hf-select-people {
    .mat-form-field-flex .mat-form-field-outline{
      color: #fff !important;
    }
  }
}

.exit-intent-dialog {
  .mat-dialog-container {
    border-radius: 16px;
    padding: 24px 16px;
  }
  .mat-bottom-sheet-container {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 24px 16px;
    max-height: unset;
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
}

.skeleton {
  min-height: 30px;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200% 100% !important;
  animation: loading 1.5s infinite;
  border-radius: 4px;
  border: none !important;
}
@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.prevent-click {
  pointer-events: none;
}


@media only screen and (min-width: 767px) {
  .mobile-only {
    display: none !important;
  }
}


@media only screen and (max-width: 767px) {
  .desk-only {
    display: none !important;
  }
}

.sip-resume-dialog {
  .mat-dialog-container {
    border-radius: 20px;
  }
}


.dmsans {
  * {
    &:not(mat-icon) {
      font-family: 'DM Sans', sans-serif !important;
    }
    &.material-icons {
      font-family: 'Material Icons' !important;
    }
    &[class^=icon-], &[class*=" icon-"]{
      font-family: 'icomoon' !important;
    }
  }
  .mat-dialog-container .option-container .options .option-tab .mat-button-wrapper .icon-bank{
    font-size: 24px !important;
    margin-top: 2px !important; 
  }

  @media (max-width: 768px) {
    .mat-tab-label-content{
      font-size: 17px !important;
    }
    .mat-tab-label-content span{
      font-size: 17px !important;
    }
  }

  @media (max-width: 376px) {
    .mat-tab-label-content{
      font-size: 16px !important;
    }
    .mat-tab-label-content span{
      font-size: 16px !important;
    }
  }
}

.is-loading {
  .loading-section,
  h2,
  p {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  .loading-section {
    height: 200px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  h2 {
    height: 30px;
  }

  p {
    height: 70px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.blur-image{
  filter: blur(17px) !important;
}
